import { useState, useLayoutEffect, MutableRefObject } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface Size {
  width: number;
  height: number;
}

export const useElementSize = <T extends HTMLElement = HTMLElement>(
  targetRef: MutableRefObject<T | null>,
) => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setRoundedSize = ({ width, height }: Size) => {
    setSize({ width: Math.round(width), height: Math.round(height) });
  };

  useLayoutEffect(() => {
    targetRef.current && setRoundedSize(targetRef.current.getBoundingClientRect());
  }, [targetRef]);

  useResizeObserver(targetRef, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return size;
};
