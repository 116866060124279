import React, { ReactNode, PropsWithChildren, forwardRef } from 'react';
import cn from 'classnames';
import { getSizeClassName } from '~common-utils/get-size-classname';
import { TSize } from '~common-types/size';
import { Text } from '~atoms/Text';
import { ActionTag } from '~atoms/ActionTag';
import { IActionTagProps } from '~atoms/ActionTag/types';
import styles from './Button.module.scss';

export const buttonTypes = ['primary', 'secondary', 'text', 'promocode', 'custom'];

interface IButtonProps extends IActionTagProps {
  type?: 'primary' | 'secondary' | 'text' | 'promocode' | 'custom';
  size?: TSize;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
}

export const Button = forwardRef<HTMLElement, PropsWithChildren<IButtonProps>>(
  (
    { className = '', type = 'primary', size, iconStart, iconEnd, children, ...actionTagProps },
    ref,
  ) => {
    const isStringContent = typeof children === 'string';
    return (
      <ActionTag
        ref={ref}
        className={cn(styles.button, getSizeClassName(size), styles[`button_${type}`], className)}
        {...actionTagProps}
      >
        {iconStart && <div className={styles.button__icon}>{iconStart}</div>}
        {isStringContent ? <Text className={styles.button__text}>{children}</Text> : children}
        {iconEnd && <div className={styles.button__icon}>{iconEnd}</div>}
      </ActionTag>
    );
  },
);
Button.displayName = 'Button';
