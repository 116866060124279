import React from 'react';
import cn from 'classnames';
import { ActionTag } from '~atoms/ActionTag';
import { Heart } from '~atoms/icons';
import { FlagTag } from '~atoms/FlagTag';
import { TTopOfferHeader } from '../types';
import styles from './Header.module.scss';

type THeaderProps = TTopOfferHeader & {
  className?: string;
  onFavoriteClick?: () => Promise<void>;
};

export const Header: React.FC<THeaderProps> = ({
  className = '',
  isBetterWithPrime,
  isBetterWithPrimePlus,
  isFavorite,
  onFavoriteClick,
}) => {
  const shouldShowPrimeMark = isBetterWithPrime || isBetterWithPrimePlus;
  return (
    <div className={cn(styles.header, className)}>
      {shouldShowPrimeMark ? (
        <FlagTag className={styles.header__primeMark} isPlus={isBetterWithPrimePlus} />
      ) : null}
      <ActionTag
        className={styles.header__favoriteBtn}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onFavoriteClick}
        disabled={!onFavoriteClick}
      >
        <Heart fill={isFavorite ? 'currentColor' : undefined} stroke='currentColor' />
      </ActionTag>
    </div>
  );
};
