import React, {
  MouseEventHandler,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';
import styles from './ActionTag.module.scss';
import { IActionTagProps } from './types';

export const ActionTag = forwardRef<HTMLElement, PropsWithChildren<IActionTagProps>>(
  (
    {
      className = '',
      disabled,
      linkSpecifics,
      buttonSpecifics,
      children,
      onClick,
      asDiv,
      shouldStopPropogationWhenActive = true,
      ...rest
    },
    ref,
  ) => {
    const isLink = Boolean(linkSpecifics?.href) && !disabled;
    const ClickableTag = asDiv ? 'div' : 'button';
    const isButton = !isLink && ClickableTag === 'button';

    const handleClick: MouseEventHandler = useCallback(
      (e) => {
        if (disabled) {
          e.preventDefault();

          return;
        }

        onClick?.(e);

        if (shouldStopPropogationWhenActive) {
          e.stopPropagation();
        }
      },
      [shouldStopPropogationWhenActive, disabled, onClick],
    );

    const commonProps = {
      className: cn(styles.actionTag, className),
      onClick: handleClick,
      ...rest,
    };

    useEffect(() => {
      if (isLink && asDiv) {
        console.warn(
          'ActionTag: Trying to setup a link element with asDiv option (link can only be presented by tag "a")',
        );
      }
    }, [isLink, asDiv]);
    return isLink ? (
      <a
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref={ref as any}
        {...linkSpecifics}
        {...commonProps}
      >
        {children}
      </a>
    ) : (
      <ClickableTag
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ref={ref as any}
        {...(isButton && buttonSpecifics)}
        {...commonProps}
        disabled={disabled}
      >
        {children}
      </ClickableTag>
    );
  },
);
ActionTag.displayName = 'ActionTag';
