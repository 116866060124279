import React from 'react';
import styles from './Header.module.scss';
import sberLogo from '~shared/source/sber-logo.svg';
import enterIcon from '~shared/source/enter-icon.svg';

export const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <img className={styles.sberLogo} src={sberLogo} alt='' />
      <button className={styles.enterButton} type='submit'>
        <img className={styles.enterIcon} src={enterIcon} alt='' />
      </button>
    </div>
  );
};
