import React, { CSSProperties, useCallback, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cn from 'classnames';
import { Text } from '~atoms/Text';
import { Button } from '~atoms/Button';
import { ProductCard } from '~molecules/ProductCard';
import { IProductData } from '~molecules/ProductCard/types';
import { TextLibrary } from '~shared/constants/text-library';
import styles from './ProductsSection.module.scss';
import { useElementSize } from 'src/shared/hooks/useElementSize';

interface IProductsSectionProps {
  className?: string;
  header: string;
  productsList: Array<IProductData>;
}

export const ProductsSection: React.FC<IProductsSectionProps> = ({
  className = '',
  header,
  productsList,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const { height } = useElementSize(itemRef);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [shouldShowExpander, setShouldShowExpander] = useState(false);

  useResizeObserver(gridRef, (entry) => {
    setShouldShowExpander(entry.target.scrollHeight > entry.target.clientHeight);
  });

  const switchFlag = useCallback(() => {
    setIsCollapsed((prevState) => !prevState);
  }, []);
  return (
    <div
      className={cn(styles.productsSection, className)}
      style={{ '--product-item-height': `${height}px` } as CSSProperties}
    >
      <Text className={styles.productsSection__header} type='headingBold' as='div'>
        {header}
      </Text>
      <div
        ref={gridRef}
        className={cn(styles.productsSection__grid, {
          [styles.productsSection__grid_collapsed]: isCollapsed,
        })}
      >
        {productsList.map((data, index) => (
          <div
            key={data.id}
            ref={!index ? itemRef : undefined}
            className={styles.productsSection__item}
          >
            <ProductCard {...data} />
          </div>
        ))}
      </div>
      {shouldShowExpander && (
        <Button
          className={styles.productsSection__showMoreBtn}
          type='secondary'
          onClick={switchFlag}
        >
          {isCollapsed ? TextLibrary.SHOW_ALL : TextLibrary.COLLAPSE}
        </Button>
      )}
    </div>
  );
};
