import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { TextLibrary } from '~shared/constants/text-library';
import { Button } from '~atoms/Button';
import { ActionTag } from '~atoms/ActionTag';
import { Spoiler } from '~atoms/Spoiler';
import { Chevron } from '~atoms/icons';
import { Text } from '~atoms/Text';
import { TTopOfferShortInfo } from '../types';
import { Header } from '../Header';
import styles from './FrontContent.module.scss';

type TFrontContentProps = TTopOfferShortInfo & {
  className?: string;
  onDetails?: () => void;
  onFavoriteClick?: () => Promise<void>;
};

export const FrontContent: React.FC<PropsWithChildren<TFrontContentProps>> = ({
  className = '',
  title,
  description,
  sponsorLink,
  isFavorite,
  isBetterWithPrime,
  isBetterWithPrimePlus,
  styling,
  onFavoriteClick,
  onDetails,
}) => {
  return (
    <div
      className={cn(styles.frontContent, className)}
      style={{ backgroundImage: styling.backgroundGradient }}
    >
      <Header
        isFavorite={isFavorite}
        isBetterWithPrime={isBetterWithPrime}
        isBetterWithPrimePlus={isBetterWithPrimePlus}
        onFavoriteClick={onFavoriteClick}
      />
      <div
        className={cn(styles.frontContent__image)}
        style={{ backgroundImage: `url(${styling.imageUrl})` }}
      />
      <Text
        className={styles.frontContent__title}
        type='headingBold'
        as='div'
        style={{ color: styling?.titleColor }}
      >
        {title}
      </Text>
      <Text className={styles.frontContent__description} as='div'>
        {description}
      </Text>
      <div className={styles.frontContent__footer}>
        <Button
          className={styles.frontContent__detailsBtn}
          type='text'
          iconEnd={<Chevron size='fullHeight' />}
          onClick={onDetails}
        >
          {TextLibrary.DETAILS}
        </Button>
        <ActionTag
          className={styles.frontContent__sponsorLink}
          linkSpecifics={{ href: sponsorLink }}
        >
          <Spoiler
            className={styles.frontContent__spoiler}
            title={TextLibrary.ADVT}
            blockPosition='end'
            inlinePosition='end'
          >
            {`${TextLibrary.SPONSOR}: ${sponsorLink}`}
          </Spoiler>
        </ActionTag>
      </div>
    </div>
  );
};
