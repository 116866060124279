import React from 'react';
import { TSquareIconProps, TMonochromeIconProps } from './types';

export const Chevron: React.FC<TSquareIconProps<TMonochromeIconProps>> = ({
  size = 24,
  color = 'currentColor',
  ...otherAttributes
}) => {
  const isFullHeight = size === 'fullHeight';
  const isFullWidth = size === 'fullWidth';
  const width = isFullWidth ? '100%' : isFullHeight ? 'auto' : size;
  const height = isFullHeight ? '100%' : isFullWidth ? 'auto' : size;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      {...otherAttributes}
    >
      <path
        d='M9 19L16 12L9 5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
