import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { TextLibrary } from '~shared/constants/text-library';
import { Button } from '~atoms/Button';
import { ActionTag } from '~atoms/ActionTag';
import { Spoiler } from '~atoms/Spoiler';
import { PromoCode } from '~atoms/PromoCode';
import { Chevron } from '~atoms/icons';
import { Text } from '~atoms/Text';
import { TTopOfferDetails } from '../types';
import styles from './BackContent.module.scss';
import { Header } from '../Header';

type TBackContentProps = TTopOfferDetails & {
  className?: string;
  onFavoriteClick?: () => Promise<void>;
};

export const BackContent: React.FC<PropsWithChildren<TBackContentProps>> = ({
  className = '',
  title,
  description,
  sponsorLink,
  conditionsLink,
  targetLink,
  deadline,
  isFavorite,
  isBetterWithPrime,
  isBetterWithPrimePlus,
  promocode,
  styling,
  onFavoriteClick,
}) => {
  return (
    <div className={cn(styles.backContent, className)}>
      <Header
        isFavorite={isFavorite}
        isBetterWithPrime={isBetterWithPrime}
        isBetterWithPrimePlus={isBetterWithPrimePlus}
        onFavoriteClick={onFavoriteClick}
      />
      <Text
        className={styles.backContent__title}
        type='headingBold'
        as='div'
        style={{ color: styling?.titleColor }}
      >
        {title}
      </Text>
      <Text className={styles.backContent__description} as='div'>
        {description}
      </Text>
      {promocode && <PromoCode className={styles.backContent__promocode} promocode={promocode} />}
      <Button
        className={styles.backContent__targetBtn}
        iconEnd={<Chevron size='fullHeight' />}
        linkSpecifics={{ href: targetLink.href }}
      >
        {targetLink.text}
      </Button>
      <Text className={styles.backContent__deadline} as='div'>
        {deadline}
      </Text>
      <div className={styles.backContent__footer}>
        <Button
          className={styles.backContent__conditionsBtn}
          type='text'
          linkSpecifics={{ href: conditionsLink }}
        >
          {TextLibrary.PROMO_CONDITIONS}
        </Button>
        <ActionTag
          className={styles.backContent__sponsorLink}
          linkSpecifics={{ href: sponsorLink }}
        >
          <Spoiler
            className={styles.backContent__spoiler}
            title={TextLibrary.ADVT}
            blockPosition='end'
            inlinePosition='end'
          >
            {`${TextLibrary.SPONSOR}: ${sponsorLink}`}
          </Spoiler>
        </ActionTag>
      </div>
    </div>
  );
};
