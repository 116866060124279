import { createContext, useContext } from 'react';
import { Theme } from './types';

const ThemeContext = createContext<Theme>(Theme.default);

export const ThemeProvider = ThemeContext.Provider;

export const useTheme = () => {
  const theme = useContext(ThemeContext);

  return {
    theme,
    isDefaultTheme: theme === Theme.default,
    isPrivateTheme: theme === Theme.private,
    themeClassName: `global-theme_${theme}`,
  };
};
