export const TextLibrary = {
  SHOW_ALL: 'Показать ещё',
  COLLAPSE: 'Свернуть',
  DETAILS: 'Подробнее',
  BUY: 'Купить',
  SPONSOR: 'Рекламодатель',
  ADVT: 'Реклама',
  PROMO_CONDITIONS: 'Условия акции',
  PRIME_ADVISE: 'Выгоднее с Праймом',
  PRIME_PLUS_ADVISE: 'Выгоднее с Прайм +',
};
