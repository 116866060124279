import React from 'react';
import cn from 'classnames';
import { Text } from '~atoms/Text';
import { Button } from '~atoms/Button';
import { Chevron } from '~atoms/icons';
import { useTheme } from '~shared/contexts/Theme';
import { TextLibrary } from 'src/shared/constants/text-library';
import { Spoiler } from '~atoms/Spoiler';
import { ActionTag } from '~atoms/ActionTag';
import { Theme } from '~shared/contexts/Theme';
import ImageDefault from '~shared/source/subscriptionImage/default.png';
import styles from './SubscriptionBlock.module.scss';

interface ISubscriptionBlockProps {
  className?: string;
  header: string;
  description: string;
  subcriptionLink: {
    href: string;
    text: string;
  };
  sponsorLink: string;
}

export const SubscriptionSrc: Record<Theme, string> = {
  [Theme.default]: ImageDefault,
  [Theme.private]: '',
};

export const SubscriptionBlock: React.FC<ISubscriptionBlockProps> = ({
  className = '',
  header,
  description,
  subcriptionLink,
  sponsorLink,
}) => {
  const { theme } = useTheme();
  return (
    <div className={cn(styles.subscriptionBlock, className)}>
      <div className={styles.subscriptionBlock__wrapper}>
        <Text className={styles.subscriptionBlock__header} type='headingBold' as='div'>
          {header}
        </Text>
        <Text className={styles.subscriptionBlock__description} as='div'>
          {description}
        </Text>
        <img
          className={cn(styles.subscriptionBlock__image_mobile)}
          src={SubscriptionSrc[theme]}
          alt=''
        />
        <Button
          className={styles.subscriptionBlock__targetBtn}
          iconEnd={<Chevron size='fullHeight' />}
          linkSpecifics={{ href: subcriptionLink.href }}
        >
          {subcriptionLink.text}
        </Button>
      </div>
      <img
        className={cn(styles.subscriptionBlock__image_tablet)}
        src={SubscriptionSrc[theme]}
        alt=''
      />
      <ActionTag
        className={styles.subscriptionBlock__sponsorLink}
        linkSpecifics={{ href: sponsorLink }}
      >
        <Spoiler
          className={styles.subscriptionBlock__spoiler}
          title={TextLibrary.ADVT}
          blockPosition='end'
          inlinePosition='end'
        >
          {`${TextLibrary.SPONSOR}: ${sponsorLink}`}
        </Spoiler>
      </ActionTag>
    </div>
  );
};
