import React, { PropsWithChildren, useState } from 'react';
import cn from 'classnames';
import { Button } from '~atoms/Button';
import { Chevron } from '~atoms/icons';
import styles from './Spoiler.module.scss';

type TPosition = 'start' | 'end';

interface ISpoilerProps {
  className?: string;
  blockPosition?: TPosition;
  inlinePosition?: TPosition;
  title?: string;
}

export const Spoiler: React.FC<PropsWithChildren<ISpoilerProps>> = ({
  className = '',
  inlinePosition = 'start',
  blockPosition = 'end',
  children,
  title,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={cn(styles.spoiler, className)}>
      <div
        className={cn(
          styles.spoiler__bubble,
          styles[`spoiler__bubble_position-inline_${inlinePosition}`],
          styles[`spoiler__bubble_position-block_${blockPosition}`],
          {
            [styles.spoiler__bubble_visible]: isVisible,
          },
        )}
      >
        {children}
      </div>
      <Button
        className={styles.spoiler__anchor}
        type='text'
        iconEnd={<Chevron size='fullHeight' />}
        onMouseEnter={() => {
          setIsVisible(true);
        }}
        onMouseLeave={() => {
          setIsVisible(false);
        }}
      >
        {title}
      </Button>
    </div>
  );
};
