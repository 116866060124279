import React, { useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { Text } from '~atoms/Text';
import { Button } from '~atoms/Button';
import { ActionTag } from '~atoms/ActionTag';
import { Spoiler } from '~atoms/Spoiler';
import { Heart } from '~atoms/icons';
import { Chevron } from '~atoms/icons';
import { IProductData } from './types';
import styles from './ProductCard.module.scss';
import { TextLibrary } from 'src/shared/constants/text-library';

interface IProductCardProps extends IProductData {
  className?: string;
  addToFavorite?: (offerId: string | number) => Promise<void>;
  removeFromFavorite?: (offerId: string | number) => Promise<void>;
}

export const ProductCard: React.FC<IProductCardProps> = ({
  className = '',
  id,
  imageUrl,
  currentPrice,
  discount,
  oldPrice,
  description,
  sponsorLink,
  targetLink,
  isFavorite: isFavoriteInitial,
  addToFavorite,
  removeFromFavorite,
}) => {
  const [isFavorite, setIsFavorite] = useState(isFavoriteInitial);

  const onFavoriteClick = useMemo(() => {
    if (isFavorite && removeFromFavorite) {
      return () =>
        removeFromFavorite(id).then(() => {
          setIsFavorite(false);
        });
    } else if (addToFavorite) {
      return () =>
        addToFavorite(id).then(() => {
          setIsFavorite(true);
        });
    }
  }, [id, isFavorite, addToFavorite, removeFromFavorite]);

  useEffect(() => {
    setIsFavorite(isFavoriteInitial);
  }, [isFavoriteInitial]);
  return (
    <div className={cn(styles.productCard, className)}>
      <ActionTag
        className={styles.productCard__favoriteBtn}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={onFavoriteClick}
        disabled={!onFavoriteClick}
      >
        <Heart fill={isFavorite ? 'currentColor' : undefined} stroke='currentColor' />
      </ActionTag>
      <div
        className={cn(styles.productCard__image)}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className={styles.productCard__discount}>
          <Text className={styles.productCard__discountText} type='headingBold'>
            {discount}
          </Text>
        </div>
      </div>
      <Text className={styles.productCard__currentPrice} type='headingBold' as='div'>
        {currentPrice}
      </Text>
      <Text className={styles.productCard__oldPrice} as='div'>
        {oldPrice}
      </Text>
      <Text className={styles.productCard__description} as='div'>
        {description}
      </Text>
      <Button
        className={styles.productCard__targetBtn}
        iconEnd={<Chevron size='fullHeight' />}
        linkSpecifics={{ href: targetLink }}
      >
        {TextLibrary.BUY}
      </Button>
      <div className={styles.productCard__footer}>
        <ActionTag
          className={styles.productCard__sponsorLink}
          linkSpecifics={{ href: sponsorLink }}
        >
          <Spoiler
            className={styles.productCard__spoiler}
            title={TextLibrary.ADVT}
            blockPosition='end'
            inlinePosition='end'
          >
            {`${TextLibrary.SPONSOR}: ${sponsorLink}`}
          </Spoiler>
        </ActionTag>
      </div>
    </div>
  );
};
