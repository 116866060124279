import React from 'react';
import styles from './Footer.module.scss';
import sberLogo from '~shared/source/sber-logo.svg';

export const Foooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__content}>
        <img className={styles.footer__sberLogo} src={sberLogo} alt='' />
        <section className={styles.footer__contactSection}>
          <div className={styles.footer__contactContainer}>
            <p className={styles.footer__contactNumber}>+7 (495) 500-55-50</p>
            <p className={styles.footer__contactDescription}>
              Телефон для звонков из любой точки мира
            </p>
          </div>
          <div className={styles.footer__contactContainer}>
            <p className={styles.footer__contactNumber}>900</p>
            <p className={styles.footer__contactDescription}>
              Бесплатно с мобильного на территории РФ
            </p>
          </div>
        </section>
        <section className={styles.footer__legalSection}>
          <p className={styles.footer__legalText}>
            ПАО Сбербанк. Генеральная лицензия Банка России на осуществление банковских операций
            №1481 от 11.08.2015
          </p>
          <p className={styles.footer__legalText}>
            © 1997—2023 ПАО Сбербанк. Россия, Москва, 117997, ул. Вавилова, д. 19, тел. +7 (495)
            500 5550, 8 800 555 5550.
          </p>
        </section>
      </div>
    </footer>
  );
};
