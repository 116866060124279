export const topOfferListMockup = [
  {
    styling: {
      id: 'someId',
      titleColor: 'var(--sber-blue)',
      backgroundGradient: 'var(--gradient-violet-to-dark)',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    },
    id: 0,
    title: '20%',
    description: 'кэшбек на первый заказ по карте Сбера или по промокоду',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
    shouldShowPrimeMark: false,
    detailsTitle: '20% кэшбек',
    detailsDescription:
      'на первый заказ по карте Сбера или по промокоду. Только для новых клиентов',
    deadline: 'До 6 ноября',
    targetLink: {
      href: '#',
      text: 'Оформить заказ',
    },
    promocode: 'GUFRUS392C10',
    conditionsLink: '#',
  },
  {
    styling: {
      id: 'someId',
      titleColor: 'var(--sber-blue)',
      backgroundGradient: 'var(--gradient-violet-to-dark)',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    },
    id: 1,
    title: '20%',
    description: 'кэшбек на первый заказ по карте Сбера или по промокоду',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
    shouldShowPrimeMark: false,
    detailsTitle: '20% кэшбек',
    detailsDescription:
      'на первый заказ по карте Сбера или по промокоду. Только для новых клиентов',
    deadline: 'До 6 ноября',
    targetLink: {
      href: '#',
      text: 'Оформить заказ',
    },
    promocode: 'GUFRUS392C10',
    conditionsLink: '#',
  },
  {
    styling: {
      id: 'someId',
      titleColor: 'var(--sber-blue)',
      backgroundGradient: 'var(--gradient-violet-to-dark)',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    },
    id: 2,
    title: '20%',
    description: 'кэшбек на первый заказ по карте Сбера или по промокоду',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
    shouldShowPrimeMark: false,
    detailsTitle: '20% кэшбек',
    detailsDescription:
      'на первый заказ по карте Сбера или по промокоду. Только для новых клиентов',
    deadline: 'До 6 ноября',
    targetLink: {
      href: '#',
      text: 'Оформить заказ',
    },
    promocode: 'GUFRUS392C10',
    conditionsLink: '#',
  },
  {
    styling: {
      id: 'someId',
      titleColor: 'var(--sber-blue)',
      backgroundGradient: 'var(--gradient-violet-to-dark)',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    },
    id: 3,
    title: '20%',
    description: 'кэшбек на первый заказ по карте Сбера или по промокоду',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
    shouldShowPrimeMark: false,
    detailsTitle: '20% кэшбек',
    detailsDescription:
      'на первый заказ по карте Сбера или по промокоду. Только для новых клиентов',
    deadline: 'До 6 ноября',
    targetLink: {
      href: '#',
      text: 'Оформить заказ',
    },
    promocode: 'GUFRUS392C10',
    conditionsLink: '#',
  },
  {
    styling: {
      id: 'someId',
      titleColor: 'var(--sber-blue)',
      backgroundGradient: 'var(--gradient-violet-to-dark)',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    },
    id: 4,
    title: '20%',
    description: 'кэшбек на первый заказ по карте Сбера или по промокоду',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
    shouldShowPrimeMark: false,
    detailsTitle: '20% кэшбек',
    detailsDescription:
      'на первый заказ по карте Сбера или по промокоду. Только для новых клиентов',
    deadline: 'До 6 ноября',
    targetLink: {
      href: '#',
      text: 'Оформить заказ',
    },
    promocode: 'GUFRUS392C10',
    conditionsLink: '#',
  },
];

export const productsSectionMockup = [
  {
    id: 0,
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    discount: '-46%',
    currentPrice: '1 449 ₽',
    oldPrice: '2 699 ₽',
    description: 'Кофе в зернах Bushido | Red Katana, 1 кг',
    targetLink: '#',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
  },
  {
    id: 1,
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    discount: '-46%',
    currentPrice: '1 449 ₽',
    oldPrice: '2 699 ₽',
    description: 'Кофе в зернах Bushido | Red Katana, 1 кг',
    targetLink: '#',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
  },
  {
    id: 2,
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    discount: '-46%',
    currentPrice: '1 449 ₽',
    oldPrice: '2 699 ₽',
    description: 'Кофе в зернах Bushido | Red Katana, 1 кг',
    targetLink: '#',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
  },
  {
    id: 3,
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    discount: '-46%',
    currentPrice: '1 449 ₽',
    oldPrice: '2 699 ₽',
    description: 'Кофе в зернах Bushido | Red Katana, 1 кг',
    targetLink: '#',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
  },
  {
    id: 4,
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/39/Domestic_Goose.jpg',
    discount: '-46%',
    currentPrice: '1 449 ₽',
    oldPrice: '2 699 ₽',
    description: 'Кофе в зернах Bushido | Red Katana, 1 кг',
    targetLink: '#',
    sponsorLink: 'https://sberprime.sber.ru/youth',
    isFavorite: false,
  },
];
