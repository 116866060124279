import React from 'react';
import { TSquareIconProps, TMonochromeIconProps } from './types';

export const Copy: React.FC<TSquareIconProps<TMonochromeIconProps>> = ({
  size = 24,
  color = 'currentColor',
  ...otherAttributes
}) => {
  const isFullHeight = size === 'fullHeight';
  const isFullWidth = size === 'fullWidth';
  const width = isFullWidth ? '100%' : isFullHeight ? 'auto' : size;
  const height = isFullHeight ? '100%' : isFullWidth ? 'auto' : size;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      {...otherAttributes}
    >
      <path
        d='M19.2667 8H9.73333C8.77604 8 8 8.52912 8 9.18182V19.8182C8 20.4709 8.77604 21 9.73333 21H19.2667C20.224 21 21 20.4709 21 19.8182V9.18182C21 8.52912 20.224 8 19.2667 8Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.63723 14L2.18182 14C1.86838 14 1.56778 13.8174 1.34615 13.4923C1.12451 13.1673 1 12.7264 1 12.2667L1 2.73333C1 2.27362 1.12451 1.83274 1.34615 1.50768C1.56778 1.18262 1.86838 0.999999 2.18182 0.999999L12.8182 1C13.1316 1 13.4322 1.18262 13.6539 1.50768C13.8755 1.83274 14 2.27362 14 2.73333L14 4.42314'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
