import React, { ElementType, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Text.module.scss';

interface ITextProps extends HTMLAttributes<HTMLElement> {
  as?: ElementType;
  type?: 'heading' | 'headingBold' | 'normal' | 'cond';
  children?: ReactNode;
}

export const Text: React.FC<ITextProps> = ({
  as: Tag = 'span',
  className,
  type = 'normal',
  children = '',
  ...otherProps
}) => {
  return (
    <Tag {...otherProps} className={cn(styles.text, styles[`text__${type}`], className)}>
      {children}
    </Tag>
  );
};
