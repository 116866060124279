import React, { useState, useCallback, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { ActionTag } from '~atoms/ActionTag';
import { BackContent } from './BackContent';
import { ITopOfferData } from './types';
import { FrontContent } from './FrontContent';
import styles from './TopOfferCard.module.scss';

interface ITopOfferCardProps extends ITopOfferData {
  className?: string;
  addToFavorite?: (offerId: string | number) => Promise<void>;
  removeFromFavorite?: (offerId: string | number) => Promise<void>;
}

export const TopOfferCard: React.FC<ITopOfferCardProps> = ({
  className = '',
  styling,
  id,
  title,
  description,
  sponsorLink,
  isFavorite: isFavoriteInitial,
  isBetterWithPrime,
  isBetterWithPrimePlus,
  detailsTitle,
  detailsDescription,
  deadline,
  targetLink,
  promocode,
  conditionsLink,
  addToFavorite,
  removeFromFavorite,
}) => {
  const [isFavorite, setIsFavorite] = useState(isFavoriteInitial);
  const onFavoriteClick = useMemo(() => {
    if (isFavorite && removeFromFavorite) {
      return () =>
        removeFromFavorite(id).then(() => {
          setIsFavorite(false);
        });
    } else if (addToFavorite) {
      return () =>
        addToFavorite(id).then(() => {
          setIsFavorite(true);
        });
    }
  }, [id, isFavorite, addToFavorite, removeFromFavorite]);

  const [isTurned, setIsTurned] = useState(false);
  const turnCard = useCallback(() => {
    setIsTurned((prevValue?: boolean) => !prevValue);
  }, []);

  useEffect(() => {
    setIsFavorite(isFavoriteInitial);
  }, [isFavoriteInitial]);
  return (
    <ActionTag
      className={cn(styles.topOfferCard, { [styles.topOfferCard_turned]: isTurned }, className)}
      onClick={() => {
        setIsTurned(true);
      }}
      onMouseLeave={() => {
        setIsTurned(false);
      }}
    >
      <FrontContent
        styling={styling}
        title={title}
        description={description}
        sponsorLink={sponsorLink}
        onDetails={turnCard}
        isFavorite={isFavorite}
        isBetterWithPrime={isBetterWithPrime}
        isBetterWithPrimePlus={isBetterWithPrimePlus}
        onFavoriteClick={onFavoriteClick}
      />
      <BackContent
        styling={styling}
        title={detailsTitle || title}
        description={detailsDescription || description}
        sponsorLink={sponsorLink}
        deadline={deadline}
        promocode={promocode}
        targetLink={targetLink}
        conditionsLink={conditionsLink}
        isFavorite={isFavorite}
        isBetterWithPrime={isBetterWithPrime}
        isBetterWithPrimePlus={isBetterWithPrimePlus}
        onFavoriteClick={onFavoriteClick}
      />
    </ActionTag>
  );
};
