import React, { useCallback, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cn from 'classnames';
import { Text } from '~atoms/Text';
import { Button } from '~atoms/Button';
import { TopOfferCard } from '~molecules/TopOfferCard';
import { ITopOfferData } from '~molecules/TopOfferCard/types';
import { TextLibrary } from '~shared/constants/text-library';
import styles from './TopOffersSection.module.scss';

interface ITopOffersSectionProps {
  className?: string;
  header: string;
  topOffersList: Array<ITopOfferData>;
}

export const TopOffersSection: React.FC<ITopOffersSectionProps> = ({
  className = '',
  header,
  topOffersList,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [shouldShowExpander, setShouldShowExpander] = useState(false);

  useResizeObserver(gridRef, (entry) => {
    setShouldShowExpander(entry.target.scrollHeight > entry.target.clientHeight);
  });

  const switchFlag = useCallback(() => {
    setIsCollapsed((prevState) => !prevState);
  }, []);
  return (
    <div className={cn(styles.topOffersSection, className)}>
      <Text className={styles.topOffersSection__header} type='headingBold' as='div'>
        {header}
      </Text>
      <div
        ref={gridRef}
        className={cn(styles.topOffersSection__grid, {
          [styles.topOffersSection__grid_collapsed]: isCollapsed,
        })}
      >
        {topOffersList.map((data) => (
          <div key={data.id} className={styles.topOffersSection__item}>
            <TopOfferCard {...data} />
          </div>
        ))}
      </div>
      {shouldShowExpander && (
        <Button
          className={styles.topOffersSection__showMoreBtn}
          type='secondary'
          onClick={switchFlag}
        >
          {isCollapsed ? TextLibrary.SHOW_ALL : TextLibrary.COLLAPSE}
        </Button>
      )}
    </div>
  );
};
