import React, { ComponentProps, useCallback } from 'react';
import { Text } from '~atoms/Text';
import { Button } from '~atoms/Button';
import { Copy } from '~atoms/icons';

interface IPromoCodeProps extends ComponentProps<typeof Button> {
  promocode: string;
}

export const PromoCode: React.FC<IPromoCodeProps> = ({ className = '', promocode }) => {
  const onClick = useCallback(async () => {
    await navigator.clipboard.writeText(promocode);
  }, [promocode]);
  return (
    <Button
      className={className}
      type='promocode'
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={onClick}
      iconEnd={<Copy size='fullHeight' />}
    >
      <Text>{promocode}</Text>
    </Button>
  );
};
