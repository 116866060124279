import React from 'react';
import cn from 'classnames';
import { TextLibrary } from '~shared/constants/text-library';
import { Text } from '~atoms/Text';
import styles from './FlagTag.module.scss';

export const FlagTag: React.FC<{ className?: string; isPlus?: boolean }> = ({
  className = '',
  isPlus,
}) => {
  return (
    <div className={cn(styles.flagTag, className)}>
      <Text type='headingBold'>
        {isPlus ? TextLibrary.PRIME_PLUS_ADVISE : TextLibrary.PRIME_ADVISE}
      </Text>
    </div>
  );
};
