import React from 'react';
import styles from './MainPage.module.scss';
import { Header } from '~atoms/Header';
import { Foooter } from '~atoms/Footer';
import { TopOffersSection } from '~molecules/TopOffersSection';
import { ProductsSection } from '~molecules/ProductsSection';
import {
  productsSectionMockup,
  topOfferListMockup,
} from '~shared/ui/pages/MainPage/MainPage.const';
import { SubscriptionBlock } from '~molecules/SubscriptionBlock';

export const MainPage = () => {
  return (
    <div className={styles.pageContainer}>
      <Header />
      <div className={styles.contentContainer}>
        <div>Hero section</div>
        <div>Призовой фонд 100 000 000</div>
        <TopOffersSection header={'Доступно уже сегодня'} topOffersList={topOfferListMockup} />
        <SubscriptionBlock
          header={'Зелёный день еще выгоднее'}
          description={'Успей подключиться до 6 ноября.\nПредложения станут доступны раньше.'}
          subcriptionLink={{
            href: '#',
            text: 'Оформить на месяц за 399 ₽',
          }}
          sponsorLink={'#'}
        />
        <ProductsSection
          header={'Товары герои со скидкой до 20%'}
          productsList={productsSectionMockup}
        />
        <div>Доступно в зелёный день</div>
      </div>
      <Foooter />
    </div>
  );
};
